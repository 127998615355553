@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Lexend+Deca&display=swap");
:root {
  --font-body: Arial, sans-serif;
  --font-inter: "Inter", sans-serif;
  --font-laxend:"Lexend Deca", sans-serif;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --very-dark-blue: hsl(233, 47%, 7%);
  --dark-desaturated-blue: hsl(244, 38%, 16%);
  --soft-violet: hsl(277, 64%, 61%);
  --slightly-transparent-white-p:hsla(0, 0%, 100%, 0.75);
  --slightly-transparent-white-h:hsla(0, 0%, 100%, 0.6);
  --wheat-background: hsl(39.1, 76.7%, 83.1%);
  --orange-card-background:hsl(27.5, 74%, 65.3%);
  --accent-champange:hsl(32.7, 76.7%, 91.6%);
  --black-button:hsl(105, 5.9%, 13.3%);
  --text-white:hsl(0, 0%, 100%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-background: var(--very-dark-blue);
    --card-background: var(--dark-desaturated-blue);
    --violet-accent: var(--soft-violet);
    --main-paragraph:var(--slightly-transparent-white-p);
    --stat-heading:var(--slightly-transparent-white-h);
    --white-color: var(--white);
    --black-color: var(--black);
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --main-background: var(--wheat-background);
    --card-background: var(--orange-card-background);
    --violet-accent: var(--accent-champange);
    --main-paragraph:var(--slightly-transparent-white-p);
    --stat-heading:var(--slightly-transparent-white-h);
    --white-color: var(--white);
    --black-color: var(--black-button);
  }
}
[color-scheme=dark] {
  --main-background: var(--very-dark-blue);
  --card-background: var(--dark-desaturated-blue);
  --violet-accent: var(--soft-violet);
  --main-paragraph:var(--slightly-transparent-white-p);
  --stat-heading:var(--slightly-transparent-white-h);
  --white-color: var(--white);
  --black-color: var(--black);
}

[color-scheme=light] {
  --main-background: var(--wheat-background);
  --card-background: var(--orange-card-background);
  --violet-accent: var(--accent-champange);
  --main-paragraph:var(--slightly-transparent-white-p);
  --stat-heading:var(--slightly-transparent-white-h);
  --white-color: var(--white);
  --black-color: var(--black-button);
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.attribution {
  padding-bottom: 1.25rem;
  background-color: var(--main-background);
  font-size: 11px;
  text-align: center;
}
.attribution a {
  color: #3e52a3;
}

.card {
  max-width: 62.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 1.875rem;
  padding: 3.125rem 0;
}
@media (min-width: 40em) {
  .card {
    flex-direction: row;
  }
}
.card__text {
  flex-basis: 55%;
  order: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0 0 0.9375rem 0.9375rem;
  padding: 4.375rem 1.875rem;
  background-color: var(--card-background);
  color: var(--white-color);
}
@media (min-width: 40em) {
  .card__text {
    border-radius: 0.9375rem 0 0 0.9375rem;
  }
}
.card__text--header {
  margin-top: 4%;
}
.card__text--header h1 {
  text-align: center;
  font-family: var(--font-inter);
  font-size: 2.25rem;
  font-weight: 700;
}
.card__text--header h1 span {
  color: var(--violet-accent);
}
.card__text--paragraph p {
  font-family: var(--font-inter);
  font-weight: 400;
  color: var(--main-paragraph);
}
.card__text--status {
  position: relative;
  right: 30px;
  width: 100%;
}
.card__text--status ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 17%;
}
@media (min-width: 40em) {
  .card__text--status ul {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
.card__text--status ul li {
  margin-top: 10%;
  font-family: var(--font-inter);
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.card__text--status ul li span {
  font-family: var(--font-laxend);
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--main-paragraph);
}
.card__img {
  order: 1;
  flex-basis: 45%;
  background-size: cover;
  border-radius: 0.9375rem 0.9375rem 0 0;
  background-color: var(--violet-accent);
}
@media (min-width: 40em) {
  .card__img {
    order: 2;
    border-radius: 0 0.9375rem 0.9375rem 0;
  }
}
.card__img img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 0.9375rem 0.9375rem 0 0;
  mix-blend-mode: multiply;
  opacity: 0.7;
}
@media (min-width: 40em) {
  .card__img img {
    border-radius: 0 0.9375rem 0.9375rem 0;
  }
}

.container {
  margin: auto;
  min-height: 100vh;
  background-color: var(--main-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle {
  padding: 1.875rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-background);
}
.toggle__button {
  cursor: pointer;
  height: 1.875rem;
  width: 6.25rem;
  background-color: var(--violet-accent);
  border: 0.125rem solid none;
  border-radius: 10%;
  color: var(--black-color);
}
.toggle__button:hover {
  background-color: var(--violet-accent);
  opacity: 0.8;
}