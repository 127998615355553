@use '../util' as *;

.container{
    margin: auto;
    min-height: 100vh;
    background-color: var(--main-background);
    display: flex;
    justify-content: center;
    align-items: center;

}