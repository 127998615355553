@use '../util' as *;

.attribution { 
    padding-bottom:rem(20) ;
    background-color: var(--main-background);
    font-size: 11px; 
    text-align: center; 
    a { 
        color: hsl(228, 45%, 44%);
    }
}
