@use '../util' as *;

.card{
    max-width: rem(1000);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 rem(30);
    padding: rem(50) 0;
    @include breakpoint(medium){
        flex-direction: row;
    }
    // min-height: 100vh;
    //margin-left: rem(30);
    &__text{
        flex-basis: 55%;
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0 0 rem(15) rem(15);
        //margin: auto;
        padding: rem(70) rem(30);
        background-color: var(--card-background);
        color: var(--white-color);
        @include breakpoint(medium){
            border-radius: rem(15) 0 0 rem(15);
        }
        &--header{
            margin-top: 4%;
            h1{
                text-align: center;
                font-family: var(--font-inter);
                font-size: rem(36);
                font-weight: 700;
                span{
                    color: var(--violet-accent);
                }
            }
        }
        &--paragraph{
            p{
                font-family: var(--font-inter);
                font-weight: 400;
                color: var(--main-paragraph);
                //text-align: center;
            }
        }
        &--status{
            //position: relative;
            //display: flex;
            // flex-direction: column;
            // align-self: center;
            position: relative;
            right: 30px;
            width: 100%;
            //justify-content: center;
            ul{
                list-style: none;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 17%;
                @include breakpoint(medium){
                    flex-direction: row;
                    justify-content: space-evenly;
                }
                li{
                    margin-top: 10%;
                    font-family: var(--font-inter);
                    font-weight: 700;
                    font-size: rem(24);
                    text-transform: uppercase;
                    span{
                        font-family: var(--font-laxend);
                        font-size: rem(12);
                        font-weight: 400;
                        //text-transform: uppercase;
                        color: var(--main-paragraph);
                    } 
                }
            }
        }
    }
    &__img{
        order: 1;
        flex-basis: 45%;
        background-size:cover;
        border-radius: rem(15) rem(15) 0 0;
        background-color: var(--violet-accent);
        @include breakpoint(medium) {
            order: 2;
            border-radius: 0 rem(15) rem(15) 0;
        }
        img{
            max-width: 100%;
            width: 100%;
            height: 100%;
            border-radius: rem(15) rem(15) 0 0;
            mix-blend-mode: multiply;
            opacity: 0.7;
            @include breakpoint(medium){
                border-radius: 0 rem(15) rem(15) 0;
            }
        }
    }
}